


import {Component, Vue, Watch} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  TPromoPageStat,
  TUserStatMeetings,
  TUserStatMessages,
  TUserStatPromoPages
} from '@/_modules/statistics/types/promo-page-stat.type';
import { TColumns } from '@/_modules/statistics/types/column.type';
import { TCompanyRows } from '@/_modules/statistics/types/company/row-company.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import FileHelper from '@/_helpers/file.helper';
import {TEvent} from '@/_types/event.type';
import {TContact} from '@/_types/contact.type';
import EventHelper from '@/_helpers/event.helper';

@Component
export default class StatisticsCompany extends Vue {

  @Getter('statisticStore/promoPage') promoPageStat: TPromoPageStat;
  @Getter('statisticStore/isLoadingCompany') isLoadingCompanyStats: boolean;
  @Getter('promoPageStore/promoPage') promoPage: TPromoPage;
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('_eventStore/event') event: TEvent;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get isLoading(): boolean {
    return !this.promoPageStat || !this.promoPage || this.isLoadingCompanyStats;
  }

  public get isEventOwner(): boolean {
    return EventHelper.isContactEventOrganizer(this.event, this.myself);
  }

  public isExportEnabled(): boolean {
    return this.isEventOwner;
  }

  @Watch('eventId', { immediate: true })
  private onEventIdChange(): void {
    this.$store.dispatch('promoPageStore/getContactPromoPage', this.eventId);
  }

  @Watch('promoPageStat', { immediate: true })
  private onPromoPageStatChange(): void {
    this.setData();
  }

  @Watch('promoPage', { immediate: true })
  private onPromoPageChange(): void {
    if (!this.promoPage) {
      return;
    }

    this.$store.dispatch('statisticStore/getPromoPage', {
      eventId: this.eventId,
      externalId: this.promoPage.external_id,
    });
  }

  public columns: TColumns[] = [
    {
      label: 'Name',
      field: 'name',
    },
    {
      label: this.$t('result.company.viewed_contact'),
      field: 'contactsToMeViewed',
      type: 'number',
      extraClasses: 'left-border',
    },
    {
      label: this.$t('result.company.favorites'),
      field: 'contactsByMeFavorite',
      type: 'number',
    },
    {
      label: this.$t('result.company.meetings_by_me'),
      field: 'meetingsByMeTotal',
      type: 'number',
      extraClasses: 'left-border',
    },
    {
      label: this.$t('result.company.meetings_to_me'),
      field: 'meetingsToMeTotal',
      type: 'number',
    },
    {
      label: this.$t('result.company.total_messages'),
      field: 'messagesTotal',
      type: 'number',
      extraClasses: 'left-border',
    },
    {
      label: this.$t('result.company.sent_messages'),
      field: 'messagesByMeTotal',
      type: 'number',
    },
    {
      label: this.$t('result.company.received_messages'),
      field: 'messagesToMeTotal',
      type: 'number',
    },
  ];

  public rows: TCompanyRows[] = [];
  public promopages: TUserStatPromoPages = null;
  public meetings: TUserStatMeetings = {
    by_me: {
      total: 0,
    },
    to_me: {
      total: 0,
    },
    total_confirmed: 0,
    total: 0,
  };
  public messages: TUserStatMessages = {
    by_me: {
      total: 0,
    },
    to_me: {
      total: 0,
    },
    total: 0,
  };

  public getTableCellClass(name: string): string {
    switch (name) {
      case 'contactsToMeViewed':
      case 'meetingsByMeTotal':
      case 'messagesTotal':
        return 'left-border total-color';
      case 'contactsByMeFavorite':
      case 'meetingsToMeTotal':
      case 'messagesByMeTotal':
        return 'high-color';
      case 'messagesToMeTotal':
        return 'low-color';
      default:
        return null;
    }
  }

  public percents(val: number, total: number): number {
    if (total !== 0 && val < total) {
      return (val / total) * 100;
    }
    return 100;
  }

  // TODO: better naming
  public order(val: number, val2: number): number {
    return (val > val2) ? 0 : 1;
  }

  public setData(): void {
    if (!this.promoPageStat) {
      return;
    }

    this.promopages = this.promoPageStat.List[0].stat.promopages;
    this.promoPageStat.List.forEach(item => {

      this.meetings.by_me.total += item.stat.meetings.by_me.total;
      this.meetings.to_me.total += item.stat.meetings.to_me.total;
      this.meetings.total += item.stat.meetings.by_me.total + item.stat.meetings.to_me.total;
      this.meetings.total_confirmed += item.stat.meetings.by_me.confirmed + item.stat.meetings.to_me.confirmed;

      this.messages.by_me.total += item.stat.messages.by_me.total;
      this.messages.to_me.total += item.stat.messages.to_me.total;
      this.messages.total += item.stat.messages.by_me.total + item.stat.messages.to_me.total;

      const obj = {
        name: item.contact.name,
        contactsToMeViewed: item.stat.contacts.to_me.viewed,
        contactsByMeFavorite: item.stat.contacts.by_me.favorite,
        meetingsByMeTotal: item.stat.meetings.by_me.total,
        meetingsToMeTotal: item.stat.meetings.to_me.total,
        messagesTotal: item.stat.messages.by_me.total + item.stat.messages.to_me.total,
        messagesByMeTotal: item.stat.messages.by_me.total,
        messagesToMeTotal: item.stat.messages.to_me.total,
      };

      this.rows.push(obj);

    });
    this.columns[0].label = `${this.promoPageStat.List.length} ${this.$t('result.company.team_members')}`;
  }

  public async exportResultCompany(exportFileFormat: string): Promise<void> {
    const filename = 'EventsWallet_event_' + this.eventId + '_company_statistics.' + exportFileFormat;
    const file = await this.$store.dispatch('statisticStore/exportResultCompany', {
      eventId: this.eventId,
      externalId: this.promoPage.external_id,
      format: exportFileFormat
    });
    FileHelper.downloadFile(file, filename);
  }

  // TODO: this is wrong url for export contacts who visited event ← old comment, wtf...
  // public async exportEventContacts(exportFileFormat: string): Promise<void> {
  //   const filename = 'EventsWallet_event_' + this.eventId + '_contacts_statistics.' + exportFileFormat;
  //   const file = await this.$store.dispatch('statisticStore/exportEventContacts', {
  //     eventId: this.eventId,
  //     format: exportFileFormat
  //   });
  //   FileHelper.downloadFile(file, filename);
  // },

};
